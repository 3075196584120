/* ****************************** Home style **************************/
#home {
  font-family: "Lato", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}


#home .nav-link {
  display: flex;
  width: 250px;
  margin-top: 5rem;
}
#home .right img {
  width: 7rem;
}
#home h4 {
  color: #fff;
  font-size: 40px;
  font-weight: 300;
}
#home h1 {
  color: #00d6ff;
  font-size: 70px;
  letter-spacing: 1px;
  margin-top: 1rem;
}
#home h5 {
  color: #fff;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 2px;
}
#home .glitch {
  color: white;
  font-size: 20px;
  position: relative;
  margin: 0 auto;
  font-family: "Lato", sans-serif;
  letter-spacing: 1px;
}
#home .glitch:after {
  content: attr(data-text);
  position: absolute;
  left: 16px;
  text-shadow: -1px 0 red;
  top: 0;
  color: white;
  background: black;
  overflow: hidden;
  clip: rect(0, 900px, 0, 0);
  animation: noise-anim 2s infinit7e linear alternate-reverse;
}

#home .glitch:before {
  content: attr(data-text);
  position: absolute;
  left: 3px;
  text-shadow: 1px 0 blue;
  top: 0;
  color: white;
  background: transparent;
  overflow: hidden;
  clip: rect(0, 900px, 0, 0);
  animation: noise-anim-2 3s infinite linear alternate-reverse;
}
@keyframes noise-anim {
  0% {
    clip: rect(51px, 9999px, 28px, 0);
  }
  5% {
    clip: rect(70px, 9999px, 19px, 0);
  }
  10% {
    clip: rect(92px, 9999px, 13px, 0);
  }
  15% {
    clip: rect(85px, 9999px, 49px, 0);
  }
  20% {
    clip: rect(45px, 9999px, 56px, 0);
  }
  25% {
    clip: rect(26px, 9999px, 31px, 0);
  }
  30% {
    clip: rect(62px, 9999px, 73px, 0);
  }
  35% {
    clip: rect(34px, 9999px, 11px, 0);
  }
  40% {
    clip: rect(26px, 9999px, 61px, 0);
  }
  45% {
    clip: rect(34px, 9999px, 71px, 0);
  }
  50% {
    clip: rect(35px, 9999px, 69px, 0);
  }
  55% {
    clip: rect(92px, 9999px, 81px, 0);
  }
  60% {
    clip: rect(26px, 9999px, 85px, 0);
  }
  65% {
    clip: rect(2px, 9999px, 68px, 0);
  }
  70% {
    clip: rect(54px, 9999px, 79px, 0);
  }
  75% {
    clip: rect(35px, 9999px, 85px, 0);
  }
  80% {
    clip: rect(70px, 9999px, 60px, 0);
  }
  85% {
    clip: rect(32px, 9999px, 26px, 0);
  }
  90% {
    clip: rect(94px, 9999px, 53px, 0);
  }
  95% {
    clip: rect(99px, 9999px, 92px, 0);
  }
  100% {
    clip: rect(42px, 9999px, 27px, 0);
  }
}
@keyframes noise-anim-2 {
  0% {
    clip: rect(21px, 9999px, 49px, 0);
  }
  5% {
    clip: rect(27px, 9999px, 52px, 0);
  }
  10% {
    clip: rect(21px, 9999px, 70px, 0);
  }
  15% {
    clip: rect(65px, 9999px, 25px, 0);
  }
  20% {
    clip: rect(24px, 9999px, 10px, 0);
  }
  25% {
    clip: rect(61px, 9999px, 64px, 0);
  }
  30% {
    clip: rect(34px, 9999px, 47px, 0);
  }
  35% {
    clip: rect(46px, 9999px, 93px, 0);
  }
  40% {
    clip: rect(46px, 9999px, 9px, 0);
  }
  45% {
    clip: rect(67px, 9999px, 36px, 0);
  }
  50% {
    clip: rect(72px, 9999px, 20px, 0);
  }
  55% {
    clip: rect(79px, 9999px, 26px, 0);
  }
  60% {
    clip: rect(13px, 9999px, 87px, 0);
  }
  65% {
    clip: rect(10px, 9999px, 25px, 0);
  }
  70% {
    clip: rect(85px, 9999px, 11px, 0);
  }
  75% {
    clip: rect(94px, 9999px, 35px, 0);
  }
  80% {
    clip: rect(50px, 9999px, 86px, 0);
  }
  85% {
    clip: rect(74px, 9999px, 67px, 0);
  }
  90% {
    clip: rect(1px, 9999px, 40px, 0);
  }
  95% {
    clip: rect(3px, 9999px, 48px, 0);
  }
  100% {
    clip: rect(90px, 9999px, 23px, 0);
  }
}
/*====================================================================================================
Screen - Small (xs) - 480px or vertical 320px
====================================================================================================*/
@media (max-width: 767px) {
  #home .glitch:before {
    left: 43px;
  }
  #home .glitch:after {
    left: 45px;
  }
  #home h1 {
    font-size: 40px;
    margin-left: 1rem;
    margin-top: 5rem;
  }
  #home h4 {
    margin-left: 1rem;
  }
  #home h5 {
    margin-top: 5rem;
    margin-left: 1rem;
  }
  #home .glitch {
    text-align: end;
    margin-right: 1rem;
    margin-top: 2rem;
  }
  #home .nav-link {
    width: 100%;
  }
}
