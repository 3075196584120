nav {
  background: #222b31 !important;
  border-bottom: 4px solid #ee445f !important;
  font: 12px Lato !important;
  text-transform: uppercase;
  letter-spacing: 2px;
  min-height: 8vh;
}

nav figure {
  width: 80px;
  height: 15px;
}

.nav-link:focus {
  color: #fff;
}

nav figure img {
  width: 107px;
  margin-top: -4px;
}

.navbar-nav a.nav-link {
  font-size: 13px;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: none;
}

@media (max-width: 767px) {
  nav li.nav-item {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  nav li.nav-item:not(:last-child) {
    border-bottom: 0.2px solid #ffffff82;
  }

  header nav ul {
    margin-top: 1rem;
  }

  header nav ul li {
    padding: 5px 0px;
  }
  nav {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    left: 0 !important;
    z-index: 1030 !important;
    min-height: auto;
  }
}
