
  
   .btnMore {
    color: #fff;
    transition: all 0.3s;
    position: relative;
    line-height: 50px;
    text-align: center;
    cursor: pointer;
  }
   .btnMore span, .btnMore input {
    transition: all 0.3s;
    color: #00d6ff;
    font-family: "Lato";
    letter-spacing: 0.5px;
  }
   .btnMore::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    transition: all 0.3s;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-top-style: solid;
    border-bottom-style: solid;
    border-top-color: rgba(255, 255, 255, 0.5);
    border-bottom-color: rgba(255, 255, 255, 0.5);
    transform: scale(0.1, 1);
  }
   .btnMore:hover span {
    letter-spacing: 2px;
    color: #ee445f;
  }
   .btnMore:hover::before {
    opacity: 1;
    transform: scale(1, 1);
  }
   .btnMore::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: all 0.3s;
    background-color: rgb(0 214 255 / 23%);
  }
   .btnMore:hover::after {
    opacity: 0;
    transform: scale(0.1, 1);
  }