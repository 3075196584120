/* ************************************* About style *************************************/
#about {
  color: #fff;
}
#about p a,
#experience li a {
  color: #00d6ff;
  text-decoration: none;
}
#about p a:hover,
#experience li a:hover {
  color: #ee445f;
  transition: all ease 0.5s;
}
#about button {
  width: 200px;
  align-self: center;
  font-size: 14px;
  font-family: "Lato";
  font-weight: 300;
  letter-spacing: 1px;
  color: #00d6ff;
  border-color: #00d6ff;
  box-shadow: rgb(0 0 0 / 56%) 0px 22px 70px 4px;
}
#about button:hover {
  color: #ee445f;
  border-color: #ee445f;
  background-color: transparent;
}
#about .flip-card-front img {
  width: 450px;
  height: 500px;
  border-radius: 5px;
  box-shadow: #0000ff70 0px 0px 5px 8px inset,
    rgb(52 60 64 / 26%) 31px 29px 2px -4px, rgb(0 214 255 / 18%) 32px 30px;
  transition: all linear 0.3s;
  border-radius: 5px;
}
#about .flip-card-front img:hover {
  box-shadow: blue 0px 0px 5px 8px inset, rgb(52 60 64 / 26%) 15px 16px 2px -4px,
    rgb(238 68 95 / 26%) 15px 15px;
  transition: all linear 0.3s;
}
#about p {
  text-align: justify;
}
#about .flip-card {
  width: 450px;
  height: 500px;
  cursor: pointer;
}
#about .flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}
#about .flip-card-front,
#about .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}
#about .flip-card-back {
  color: white;
  transform: rotateY(180deg);
}
#about .flip-card-back img {
  width: 50%;
}
#about .card__bx {
  border-radius: 5px;
}
#about .card__bx .card__data {
  position: absolute;
  inset: 5px;
  background: rgb(52 60 64 / 45%);
}
#about .card__bx .card__data .card__content {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}
@keyframes animate {
  0% {
    background-position: -500%;
  }
  100% {
    background-position: 500%;
  }
}

@media (max-width: 767px) {
  #about {
    padding-top: 80px;
    padding-bottom: 20px;
  }
  #about .preferably-square {
    aspect-ratio: 1 / 1;
  }
  #about .photo {
    width: 80%;
  }

  #about figure {
    margin: 0;
    height: 150px;
  }
  #about .flip-card img {
    width: 100%;
    height: 100%;
  }
  #about p {
    padding: 0px 10px;
  }
  #about > div:nth-child(2) {
    justify-content: center;
  }
  #about .flip-card-back {
    height: 90%;
  }
  #about .flip-card-front {
    color: black;
    height: 100%;
  }
  #about .flip-card-front img {
    width: 80%;
    height: 80%;
  }
  #about .flip-card {
    height: 340px;
  }
}
