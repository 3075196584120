/*
file name: app.css
Author: Andres Correa Moreno
website name: My Portfolio

Dscription: File where is all the style for the page
*/

/* ********************************** General style ***********************************/

/* Hide scrollbar for Chrome, Safari and Opera */

*::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

figure{
  margin: 0;
}

#root {
  flex: 1;
  overflow: auto;
  position: relative;
}
.bg-image {
  background-image: url("./assets/img/bg2.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  filter: blur(7px);
  z-index: -1;
}

body {
  /* background-color: #192226b4; */
  background-color: transparent;
}

/* --------------------this is for cpanel and live enviroments */

.App {
  height: 90%;
}
#root,
body,
html {
  height: 100%;
}
/* --------------------this is for cpanel and live enviroments */

p {
  color: #ffffffeb;
  font-size: 16px;
  font-family: "Lato";
  letter-spacing: 0.5px;
  font-weight: 300;
  hyphens: auto;
}
h1,
h2,
h3,
h4,
h5,
label,
span {
  font-family: "Lato";
  letter-spacing: 0.2px;
}
a {
  text-decoration: none;
}
.row {
  margin: 0;
}
ul {
  padding: 0;
}
li {
  list-style: none;
}

/* ************** Contact style **************/

#contact {
  width: 100%;
  color: #fff;
}
#contact .mainContent {
  justify-content: center;
  background-color: #222b315b;
  width: 90%;
  padding: 0;
}

#contact .mainContent .leftSide {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#contact .mainContent .rightSide {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  background-color: #00000029;
}

#contact .mainContent .rightSide form button {
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
}

#contact .mainContent .rightSide form input,
#contact .mainContent .rightSide form textarea {
  width: 100%;
  resize: none;
  border: none;
  padding-left: 10px;
  background-color: #2d122cb3;
  color: #fff;
  /* background-color: #28606c; */
}

#contact .mainContent .rightSide form input:focus-visible,
#contact .mainContent .rightSide form textarea:focus-visible {
  outline: 0;
}

#contact .mainContent .rightSide form textarea {
  height: 200px;
}

#contact .mainContent .rightSide form fieldset {
  padding: 15px;
  font-size: 18px;
}

#contact .mainContent .rightSide form fieldset span {
  margin-left: 20px;
  color: #ee445f;
}

#contact .leftSide .avatar img {
  width: 100%;
  aspect-ratio: 4/2;
  object-fit: contain;
}

#contact .leftSide ul{
  width: 80%;
}
#contact .leftSide ul a{
  color: #fff;
}

#contact .leftSide ul img{
  width: 100%;
  aspect-ratio: 3/1;
  object-fit: contain;
}
#contact .leftSide ul li{
 margin-top: 10px;
}

#contact .container-form {
  display: flex;
  justify-content: center;
}
/*====================================================================================================
Screen - Small (xs) - 480px or vertical 320px
====================================================================================================*/
@media (max-width: 767px) {
  html {
    overflow-y: auto;
  }

  #experience,
  #services,
  #contact {
    margin-top: 80px;
  }

  #experience li {
    padding: 0px 10px;
  }

  #experience .job-btns {
    width: calc(100% + 50px);
    padding-left: 35px;
    margin-left: -15px;
    overflow-x: auto;
  }
  #experience .job-info {
    margin-top: 2rem !important;
  }

  #experience {
    padding-left: 15px;
    padding-right: 15px;
  }

  #experience button {
    height: 40px;
    text-align: center;
    border-left: none;
    border-bottom: 0.5px solid #6c757d;
    min-width: 190px;
    padding: 0px 15px;
  }

  .activeButton {
    color: #ee445f !important;
    border-left: none !important;
    border-bottom: 3px solid !important;
  }

  #experience .skillsProjectsContainer .skills,
  #experience .skillsProjectsContainer .projects {
    justify-content: space-between;
  }

  #experience .skills-projects-bg {
    box-shadow: none;
  }

  #services .leftServices .service,
  #services .rightServices .service {
    background: rgb(23 115 135);
    margin-top: 1rem;
  }

  #services > div {
    justify-content: center;
  }

  #services figure {
    position: absolute;
    width: 87%;
    top: 30vh;
    z-index: -2;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  html {
    overflow-y: scroll;
  }
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    min-width: 1300px;
  }
}
