/* *************************** Landing style ************************************/
.bg-landing {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #000;
  }
  
  .bg-landing div {
    position: relative;
    font-family: "Lato", sans-serif;
    text-transform: uppercase;
    letter-spacing: 4px;
    overflow: hidden;
    background: linear-gradient(120deg, #000, #fff, #000);
    background-repeat: no-repeat;
    background-size: 80%;
    animation: animate 8s linear;
    -webkit-background-clip: text;
    -webkit-text-fill-color: rgba(255, 255, 255, 0);
    -webkit-animation-fill-mode: forwards;
  }
  .bg-landing div h1 {
    font-weight: 900;
  }
  .bg-landing img {
    width: 20rem;
  }